@keyframes howworks-anim-horiz {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(18.11289px);
  }
}

@keyframes howworks-anim-horiz-r {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-18.11289px);
  }
}

@keyframes howworks-anim-vert {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(18.11289px);
  }
}

@keyframes howworks-anim-vert-r {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-18.11289px);
  }
}

@keyframes howworks-anim-rb-lt {
  from {
    transform: translateY(0px) translateX(0);
  }
  to {
    transform: translateY(-12.8px) translateX(-12.8px);
  }
}

@keyframes howworks-anim-lb-rt {
  from {
    transform: translateY(0px) translateX(0);
  }
  to {
    transform: translateY(-12.8px) translateX(12.8px);
  }
}

.mySwiper {
  width: 100%;
  height: 100%;
  background-color: transparent;


  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 0;
    width: 40px;
    height: 30px;
    margin-top: 0;
    z-index: 10;
    cursor: pointer;
    display: block;
    background-color: pink;
    left: auto;
    right: auto;
  }
}

#button_prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='6' viewBox='0 0 40 6'%3E%3Cg id='グループ_1503' data-name='グループ 1503' transform='translate(-46 -3142)'%3E%3Cpath id='パス_1849' data-name='パス 1849' d='M10,0V6H0Z' transform='translate(46 3142)' fill='%23fff'/%3E%3Crect id='長方形_1108' data-name='長方形 1108' width='30' height='1' transform='translate(56 3147)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
}

#button_next {
  background-image: url("data:image/svg+xml,%3Csvg id='グループ_1504' data-name='グループ 1504' xmlns='http://www.w3.org/2000/svg' width='40' height='6' viewBox='0 0 40 6'%3E%3Cpath id='パス_1849' data-name='パス 1849' d='M0,0V6H10Z' transform='translate(30)' fill='%23fff'/%3E%3Crect id='長方形_1108' data-name='長方形 1108' width='30' height='1' transform='translate(0 5)' fill='%23fff'/%3E%3C/svg%3E%0A");
}

svg {
  #g_x5F_v2c_p {
    animation: howworks-anim-horiz 0.8s linear infinite;
  }

  #g_x5F_r2t2_p, #g_x5F_r2t1_p {
    animation: howworks-anim-horiz-r 0.8s linear infinite;
  }

  #g_x5F_t2d_p, #g_x5F_t2p_p {
    animation: howworks-anim-vert 0.8s linear infinite;
  }

  #g_x5F_c2r_p {
    animation: howworks-anim-vert-r 0.8s linear infinite;
  }

  #right_bottom_p, #left_bottom_right_p {
    animation: howworks-anim-rb-lt 0.8s linear infinite;
  }

  #left_bottom_left_p, #g_x5F_m2c_p {
    animation: howworks-anim-lb-rt 0.8s linear infinite;
  }

  .framed-object {
    stroke: #fff;
    stroke-opacity: 0.8;
    stroke-width: 1.5px;
  }

  #playdata-hover {
    opacity: 0;
    transition: opacity 0.15s linear;

    &.hover {
      opacity: 1;
    }
  }
}
