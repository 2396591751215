body {
  header {
    display: none;
  }

  &.header {


    header {
      //display: block;
    }

    /*
    ._loading_overlay_wrapper {
      min-height: calc(100vh - 75px);
      @media (min-width: 700px) {
        min-height: calc(100vh - 100px);
      }
    }
    .layout-wrapper {
      margin-top: 75px;
      @media (min-width: 700px) {
        margin-top: 100px;
      }
      .main-content-inner {
        min-height: calc(100vh - 75px);
        @media (min-width: 700px) {
          min-height: calc(100vh - 100px);
        }
      }
    }
     */
  }
}
