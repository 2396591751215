
@keyframes top-scroll-cursor-keyframes {
  from {
    top: 0;
  }
  to {
    top: calc(100% - 20px);
  }
}

.top-scroll-wrap {
  &:after{
    animation: top-scroll-cursor-keyframes 1.2s ease-out infinite;
  }
}

#top-section {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100 - 34px);

  @media (min-width: 700px) {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.service-description {

}