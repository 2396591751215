.DBDPullDown {
  display: inline-block;
  background-color: #f8f8f8;

  &[fullwidth] {
    display: block;

    .dropdown {
      .dropdown-toggle {
        width: 100%;
      }
    }
  }

  &[error="1"] {
    .dropdown {
      .dropdown-toggle {
        border: 4px solid #cb0e0e;
        line-height: 34px;
      }
    }
  }

  .dropdown {
    min-width: 100%;

    &.show {
      .dropdown-toggle {
        border-radius: 4px 4px 0 0;
        border-bottom-width: 0;

        //background-color: #525252;
        //border-color: #525252;
        //color: #fff;
        //&::after {
        //  transform: rotateZ(180deg);
        //  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath id='_' data-name='&lt;' d='M240.728,238.757l4.445-4.256-.777-.744-5.223,5,5.223,5,.777-.744Z' transform='translate(-233.757 245.174) rotate(-90)' fill='%23fff'/%3E%3C/svg%3E%0A");
        //}
      }
    }

    &:not(.show) {
      .dropdown-toggle {
        &:hover {
          background-color: #000000;
          border-color: #000000;

          color: #fff;

          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath id='_' data-name='&lt;' d='M240.728,238.757l4.445-4.256-.777-.744-5.223,5,5.223,5,.777-.744Z' transform='translate(-233.757 245.174) rotate(-90)' fill='%23fff'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }

    .dropdown-toggle {
      min-width: 100%;
      background-color: transparent;
      border: 1px solid #000000;
      color: #000000;
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;

      display: inline-block;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;

      user-select: none;
      padding: 0;
      transition: color 0.15s linear, background-color 0.15s linear;
      //padding: 0 42px 0 42px;
      height: 40px;
      line-height: 40px;
      position: relative;

      &:focus {
        box-shadow: none;
      }

      &::after {
        content: "";
        position: absolute;
        right: 10px;
        top: 16px;
        width: 10px;
        height: 6px;
        transition: transform 0.15s linear;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath id='_' data-name='&lt;' d='M240.728,238.757l4.445-4.256-.777-.744-5.223,5,5.223,5,.777-.744Z' transform='translate(-233.757 245.174) rotate(-90)'/%3E%3C/svg%3E%0A");
        border: none;
      }

      .front-label {
        text-align: center;
        position: relative;
        //letter-spacing: 0.06em;
        .dummy-label {
          position: relative;
          top: -1px;
          height: 0.5px;
          overflow-y: hidden;
          pointer-events: none;
          color: transparent;
          opacity: 0;

          .dummy-item {
            //letter-spacing: 0.06em;
            white-space: nowrap;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }

    .dropdown-menu {
      z-index: 1000;
      background-color: #f8f8f8;
      min-width: 100%;
      width: 100%;
      padding: 0 10px 5px 10px;
      margin: 0;
      font-size: 14px;
      //      color: #fff;

      text-align: left;
      list-style: none;
      //      background-color: #525252;
      background-clip: padding-box;
      border: none;
      border-radius: 0 0 4px 4px;
      //border-radius: 0 0 0px 0px;
      margin-top: -2px;
      border: 1px solid #000000;
      border-top-width: 0;

      max-height: 300px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e3e3e3;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000;
        border-radius: 6px;
        min-height: 30px;
        width: 12px;
      }

      .dropdown-item {
        display: block;
        width: 100%;
        clear: both;
        font-size: 14px;
        font-weight: bold;
        //color: #fff;
        color: #000000;
        text-align: inherit;
        text-decoration: none;
        background-color: transparent;
        border: 0;

        //        letter-spacing: 0.06em;
        padding: 0 5px 0 15px;
        height: 40px;
        border-top: 1px solid #878787;
        white-space: nowrap;
        word-break: keep-all;
        cursor: default;
        line-height: 40px;

        outline: none !important;

        &.disabled {
          color: rgba(255, 255, 255, 0.2);
        }

        &.active {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0px;
            top: 16px;
            width: 11px;
            height: 9px;
            background-repeat: no-repeat;
            background-size: 10.097px 8.153px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.097' height='8.153' viewBox='0 0 10.097 8.153'%3E%3Cpath id='p_135' data-name='p 135' d='M2979.773,327.4l2.99,3.285,6.366-7.074' transform='translate(-2979.403 -323.275)' fill='none' stroke='%23000000' stroke-width='1'/%3E%3C/svg%3E%0A");
          }
        }

        &:not(.disabled) {
          &:not(.active) {
            &:hover {
              position: relative;

              &::after {
                content: "";
                position: absolute;
                left: 0px;
                top: 16px;
                width: 11px;
                height: 9px;
                background-repeat: no-repeat;
                background-size: 10.097px 8.153px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.097' height='8.153' viewBox='0 0 10.097 8.153'%3E%3Cpath id='p_135' data-name='p 135' d='M2979.773,327.4l2.99,3.285,6.366-7.074' transform='translate(-2979.403 -323.275)' fill='none' stroke='%23000000' stroke-width='1'/%3E%3C/svg%3E%0A");
              }
            }
          }
        }
      }
    }
  }
}
