.ContentModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  padding: 0 25px;
  pointer-events: initial;

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }


  &[statmodal] {
    .panel {
      min-width: 100% !important;
      max-width: 100% !important;
      min-height: 220px;
      max-height: 450px;
      width: 100% !important;
      padding: 20px;

      @media (min-width: 700px) {
        max-width: calc(100vw - 100px) !important;
        min-width: 600px !important;
        max-height: 600px;
        width: 100vw !important;
        padding: 40px;

        @media (min-width: 1000px) {
          max-width: 900px !important;
          min-width: 725px !important;
        }

        @media (max-height: 650px) {
          height: calc(100vh - 100px);
          max-height: calc(100vh - 100px);
        }
      }

      .panel-content {
        position: relative;
        height: 100%;

        .fixed-header {
          h3 {
            font-size: 22px;
            line-height: 24px;
            @media (min-width: 700px) {
              font-size: 26px;
              line-height: 34px;
            }
          }

          .scroll-header {
            width: calc(100% - 13px);
            @media (min-width: 700px) {
              width: calc(100% - 12px);
            }

            .scroll-header-columns {
              width: calc(100% - 12px);
              @media (min-width: 700px) {
                width: calc(100% - 26px);
              }

              .scroll-header-column {
                &:first-child {
                  padding-right: 5px;
                }

                &.last {
                  padding-right: 0;
                  @media (min-width: 700px) {
                    padding-right: 20px;
                  }

                  .dummy-label {
                    font-size: 12px;
                    padding-right: 10px;
                    @media (min-width: 700px) {
                      padding-right: 20px;
                      font-size: 20px;
                    }
                  }
                }
              }
            }
          }
        }

        .scroll-area {
          .scroll-content {
            .scroll-table {
              width: calc(100% - 24px);
              @media (min-width: 700px) {
                width: calc(100% - 38px);
              }

              tr {
                td {
                  font-size: 12px;
                  line-height: 19px;
                  padding: 15px 10px;
                  @media (min-width: 700px) {
                    padding: 20px;
                    font-size: 20px;
                    line-height: 26px;
                  }
                }

                th {
                  font-size: 12px;
                  line-height: 19px;
                  padding: 15px 0px 15px 10px;
                  @media (min-width: 700px) {
                    padding: 20px 0 20px 20px;
                    font-size: 20px;
                    line-height: 26px;
                  }
                }

                &:last-child {
                }

                span.on-off {

                }
              }
            }

            .button-area {

            }

            &.no-items {
              &::after {

              }
            }
          }
        }
      }

    }
  }

  &[selectmodal] {
    .panel {
      height: auto;
      min-width: 100% !important;
      max-width: 100% !important;
      min-height: 220px;
      max-height: 370px;
      width: 100% !important;
      @media (min-width: 700px) {
        min-width: 500px !important;
        max-width: 500px !important;
        max-height: 450px;
        width: 500px !important;
      }
      padding: 0 10px 10px;

      .panel-content {
        height: auto;
        align-items: center;
        width: 100%;

        .select-content {
          .select-title {
            height: 45px;
            line-height: 45px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid #d7d7d7;
          }

          .select-scroll-area {
            height: 100%;
            max-height: calc(370px - 45px - 10px);
            @media (min-width: 700px) {
              max-height: calc(450px - 45px - 10px);
            }

            .scroll-content {
              width: calc(100% - 24px);
              min-height: calc(220px - 45px - 10px);

              .scroll-table {
                width: 100%;
                table-layout: fixed;

                tbody {
                  tr {
                    td {
                      cursor: default;
                      vertical-align: middle;
                      height: 50px;
                      border-bottom: 1px solid #d7d7d7;
                      padding-left: 30px;
                      font-size: 14px;
                      font-weight: bold;
                      transition: background-color 0.15s linear;
                      background-color: transparent;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;

                      &:hover {
                        background-color: #f0f0f0;
                      }

                      @media (min-width: 700px) {
                        height: 40px;
                      }
                    }

                    &.selected {
                      td {
                        background-position: 10px center;
                        background-repeat: no-repeat;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.097' height='8.153' viewBox='0 0 10.097 8.153'%3E%3Cpath id='パス_163' data-name='パス 163' d='M2979.773,327.4l2.99,3.285,6.366-7.074' transform='translate(-2979.403 -323.275)' fill='none' stroke='%23525252' stroke-width='1'/%3E%3C/svg%3E%0A");
                      }
                    }
                  }
                }
              }
            }

            &::before {
              content: "";
              z-index: 1;
              position: absolute;
              right: 0;
              bottom: 0;
              pointer-events: none;
              overflow: hidden;
              top: 0;
              background-color: #7c7c7c;
              width: 12px;
              border-radius: 6px;
              margin-top: 10px;
              margin-bottom: 10px;
              opacity: 0.2;
            }

            .simplebar-vertical {
              background-color: #7c7c7c;
              width: 12px;
              border-radius: 6px;
              margin-top: 10px;
              margin-bottom: 10px;
              z-index: 2;

              .simplebar-scrollbar {
                &:before {
                  background-color: #333333;
                  border-radius: 6px;
                  left: 0px;
                  right: 0px;
                  opacity: 1;
                  top: 0;
                  bottom: 0;
                  transition: background-color 0.15s linear;
                }

                &.simplebar-hover {
                  &::before {
                    background-color: #c4c4c4;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &[customdialog] {
    &[nobuttons="1"] {

      .panel {
        height: auto;
        padding: 50px 20px 130px;
        @media (min-width: 700px) {
          min-width: 500px !important;
          max-width: 500px !important;
          width: 500px !important;
          padding: 50px 90px 130px;
        }

        .panel-content {
          background-color: white;
          display: block;
          height: auto;
          align-items: center;

          .customdialog-content {
            min-height: 100px;
            display: block;

            .description_element {
              display: flex;
              align-items: center;
              min-height: 100px;
              height: auto;
            }
          }
        }

        .close-btn {
          width: 135px;
          height: 38px;

          bottom: calc((130px - 38px) / 2);
          left: calc(50% - 130px / 2);
          @media (min-width: 700px) {
            bottom: calc((130px - 38px) / 2);
            left: calc(50% - 130px / 2);
            top: auto;
            right: auto;
          }
          border-radius: 30px;
          padding: 0;
          border: 1px solid #000;
          background-image: none;
          background-color: #ffffff;
          text-align: center;

          &:after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background-size: contain;
            transform: rotateZ(45deg);
            position: absolute;
            right: 12px;
            top: calc(50% - 5px);
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cg id='グループ_1022' data-name='グループ 1022' transform='translate(-776 -3252)'%3E%3Crect id='長方形_863' data-name='長方形 863' width='10' height='2' transform='translate(776 3256)'/%3E%3Crect id='長方形_864' data-name='長方形 864' width='2' height='10' transform='translate(780 3252)'/%3E%3C/g%3E%3C/svg%3E%0A");
          }

          &:before {
            content: "Close";
            font-size: 16px;
            font-weight: bold;
            transition: color 0.15s linear;
          }

          transition: background-color 0.15s linear;

          &:hover {
            &:after {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cg id='グループ_1022' data-name='グループ 1022' transform='translate(-776 -3252)'%3E%3Crect id='長方形_863' data-name='長方形 863' width='10' height='2' transform='translate(776 3256)' fill='%23fff'/%3E%3Crect id='長方形_864' data-name='長方形 864' width='2' height='10' transform='translate(780 3252)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
            }

            &:before {
              color: #fff;
            }

            background-image: none;
            background-color: #000;
            filter: none;
          }
        }
      }

      &[fortop="1"] {
        .panel {
          @media (min-width: 700px) {
            min-width: 750px !important;
            max-width: 750px !important;
            width: 750px !important;
            padding: 0 90px 130px;
          }

          .panel-content {
            background-color: white;
            display: block;
            height: auto;
            align-items: center;

            .customdialog-content {
              min-height: 200px;
              display: block;

              .description_element {
                display: block;
                height: auto;

                h3 {
                  height: 140px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  span {
                    line-height: 1em;
                    font-size: 32px;
                    font-weight: bold;
                  }
                }

                p {
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 26px;
                  margin-bottom: 0;
                }

                .errors {

                }
              }
            }
          }
        }
      }
    }

    .panel {
      height: 300px;
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
      @media (min-width: 700px) {
        min-width: 500px !important;
        max-width: 500px !important;
        width: 500px !important;
      }

      padding: 0 30px 0;
      @media (min-width: 700px) {
        padding: 0 40px 0;
      }

      .panel-content {
        height: 300px;
        display: flex;
        align-items: flex-start;
        width: 100%;

        .customdialog-content {
          width: 100%;
          min-height: 168px;
          min-height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .description_element {
            display: flex;
            height: 200px;
            align-items: center;

            text-align: center;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;

            & > div {
              width: 100%;
            }

            a {
              color: #525252;
            }

            ul {
              padding-left: 0;
              width: 100%;
              margin-bottom: 0;

              li {
                line-height: 1.2em;
                margin-bottom: 5px;
              }
            }
          }

          .description {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            white-space: pre-wrap;
            margin-bottom: 50px;
          }

          .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            .light-button,
            .dark-button {
              text-align: center;
              flex: 1 1 auto;
              width: 50%;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .panel {
    z-index: 1000;
    position: relative;
    background-color: #fff;
    height: 600px;
    max-height: 600px;
    //    overflow: hidden;
    width: 100vw;
    max-width: 900px;
    min-width: 725px;
    border-radius: 0px;
    filter: drop-shadow(6px 6px 12px rgba(0, 0, 0, 0.05));

    .close-btn {
      position: absolute;
      border: none;
      width: 42px;
      height: 42px;
      z-index: 100000;

      bottom: -62px;
      right: calc(50% - 21px);
      @media (min-width: 700px) {
        bottom: auto;
        top: -30px;
        right: -52px;
      }
      border-radius: 50%;
      padding: 0;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #272727;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='レイヤー_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ffffff;%7D%0A%3C/style%3E%3Cg id='グループ_310' transform='translate(-1328.674 497.075) rotate(-45)'%3E%3Cg transform='matrix(0.71, 0.71, -0.71, 0.71, 1291, 588.03)'%3E%3Cpath id='長方形_135-2' class='st0' d='M3.59,3.59L3.59,3.59c0.39-0.39,1.02-0.39,1.41,0l11.31,11.31 c0.39,0.39,0.39,1.02,0,1.41l0,0c-0.39,0.39-1.02,0.39-1.41,0L3.59,5.01C3.2,4.62,3.2,3.98,3.59,3.59z'/%3E%3C/g%3E%3Cg transform='matrix(0.71, 0.71, -0.71, 0.71, 1291, 588.03)'%3E%3Cpath id='長方形_136-2' class='st0' d='M16.32,3.6L16.32,3.6c0.39,0.39,0.39,1.02,0,1.41L5.01,16.33 c-0.39,0.39-1.02,0.39-1.41,0l0,0c-0.39-0.39-0.39-1.02,0-1.41L14.91,3.6C15.3,3.21,15.93,3.21,16.32,3.6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");

      transition: background-color 0.2s linear;

      &:hover {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='レイヤー_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23525252;%7D%0A%3C/style%3E%3Cg id='グループ_310' transform='translate(-1328.674 497.075) rotate(-45)'%3E%3Cg transform='matrix(0.71, 0.71, -0.71, 0.71, 1291, 588.03)'%3E%3Cpath id='長方形_135-2' class='st0' d='M3.59,3.59L3.59,3.59c0.39-0.39,1.02-0.39,1.41,0l11.31,11.31 c0.39,0.39,0.39,1.02,0,1.41l0,0c-0.39,0.39-1.02,0.39-1.41,0L3.59,5.01C3.2,4.62,3.2,3.98,3.59,3.59z'/%3E%3C/g%3E%3Cg transform='matrix(0.71, 0.71, -0.71, 0.71, 1291, 588.03)'%3E%3Cpath id='長方形_136-2' class='st0' d='M16.32,3.6L16.32,3.6c0.39,0.39,0.39,1.02,0,1.41L5.01,16.33 c-0.39,0.39-1.02,0.39-1.41,0l0,0c-0.39-0.39-0.39-1.02,0-1.41L14.91,3.6C15.3,3.21,15.93,3.21,16.32,3.6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        background-color: #fff;
        filter: drop-shadow(6px 6px 12px rgba(0, 0, 0, 0.05));
      }
    }

    padding: 40px;

    .panel-content {
      position: relative;
      height: 520px;

      .normal-header {
        h3 {
          font-size: 26px;
          font-weight: bold;
          line-height: 34px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        margin-bottom: 55px;
      }

      .fixed-header {
        position: absolute;

        left: 0px;
        width: 100%;
        position: relative;
        z-index: 100;
        pointer-events: none;

        h3 {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 26px;
          font-weight: bold;
          line-height: 34px;
          z-index: 2;
        }

        .scroll-header {
          background-color: #fff;
          top: 0;
          left: 0;
          width: calc(100% - 12px);
          height: 95px;
          display: flex;
          align-items: flex-end;
          position: relative;

          .scroll-header-columns {
            //            background-color: lightgreen;
            display: flex;
            width: calc(100% - 26px);

            .scroll-header-column {
              padding-bottom: 20px;
              font-size: 12px;
              line-height: 16px;
              font-weight: bold;
              flex-grow: 2;
              flex-shrink: 0;

              &.last {
                flex-grow: 0;
                padding-right: 20px;
                position: relative;

                .dummy-label {
                  position: relative;
                  font-size: 20px;
                  line-height: 26px;
                  font-weight: bold;
                  height: 1px;
                  overflow: hidden;
                  visibility: hidden;
                  position: relative;
                  padding-right: 20px;
                }

                & > span {
                  position: relative;
                  top: -1px;
                }
              }
            }
          }
        }
      }

      .scroll-area {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .scroll-content {
          padding-top: 95px;
          position: relative;

          .scroll-table {
            width: calc(100% - 38px);

            tr {
              border-top: 1px solid #dfdfdf;
              vertical-align: middle;

              &:nth-child(2n + 1) {
                background-color: #f8f8f8;
              }

              td {
                vertical-align: middle;
                width: 99%;
                padding: 20px;
                font-size: 20px;
                line-height: 26px;
                font-weight: bold;

                &.right-border {
                  border-right: 1px solid #dfdfdf;
                }

                &.last {
                  white-space: nowrap;
                  word-wrap: none;
                  word-break: keep-all;
                  text-align: right;
                }

                &.button {
                  padding: 10px;

                  a {
                    text-decoration: none;
                  }
                }
              }

              th {
                vertical-align: middle;

                padding: 20px;
                font-size: 20px;
                line-height: 26px;
                font-weight: bold;
                padding-right: 0;
                white-space: nowrap;
                word-wrap: none;
                word-break: keep-all;
              }

              &:last-child {
                border-bottom: 1px solid #dfdfdf;
              }

              &.off {
                td {
                  &.on-off-color {
                    color: #e04500;
                  }

                  span.on-off-text {
                    color: #e04500;
                  }

                  span.on-off {
                    background-color: #e04500;
                    border-color: #e04500;
                    color: #fff;
                  }
                }
              }

              span.on-off {
                width: 36px;
                height: 22px;
                border: 1px solid #dfdfdf;
                margin-left: 5px;
                font-size: 12px;
                line-height: 22px;
                display: inline-block;
                text-align: center;

                @media (min-width: 700px) {
                  font-size: 14px;
                  width: 44px;
                  height: 24px;
                  line-height: 24px;
                }
              }
            }
          }

          .button-area {
            margin-top: 40px;
            text-align: center;
            margin-right: 12px;
          }

          &.no-items {
            &::after {
              content: "No result found.";
              font-weight: bold;
              font-size: 20px;
              text-align: center;
              width: 100%;
              margin: 160px auto 0;
              display: block;
            }
          }
        }

        .simplebar-vertical {
          background-color: #f2f2f2;
          width: 12px;
          border-radius: 6px;

          .simplebar-scrollbar {
            &:before {
              background: #525252;
              border-radius: 6px;
              left: 0px;
              right: 0px;
              opacity: 1;
              top: 0;
              bottom: 0;
              transition: opacity 0.2s linear;
            }

            &.simplebar-hover {
              &::before {
                opacity: 0.5;
              }
            }
          }
        }
      }

      .button-area {
        margin-top: 60px;
        text-align: center;
      }

      .radio-items {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 40px;

        .radio-item {
          width: 33.3%;

          input {
            display: none;

            &[disabled] {
              & + label {
                opacity: 0.2;
                cursor: default;
              }
            }

            &:checked {
              & + label {
                &:after {
                  opacity: 1;
                  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'%3E%3Cg id='楕円形_5' data-name='楕円形 5' fill='%23fff' stroke='%23acacac' stroke-width='1'%3E%3Ccircle cx='17' cy='17' r='17' stroke='none'/%3E%3Ccircle cx='17' cy='17' r='16.5' fill='none'/%3E%3C/g%3E%3Ccircle id='楕円形_6' data-name='楕円形 6' cx='10' cy='10' r='10' transform='translate(7 7)' fill='%23525252'/%3E%3C/svg%3E%0A") !important;
                }
              }
            }

            &:not([disabled]) {
              &:not(:checked) {
                & + label {
                  &:hover {
                    &::after {
                      opacity: 1;
                      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'%3E%3Cg id='楕円形_5' data-name='楕円形 5' fill='%23fff' stroke='%23acacac' stroke-width='1'%3E%3Ccircle cx='17' cy='17' r='17' stroke='none'/%3E%3Ccircle cx='17' cy='17' r='16.5' fill='none'/%3E%3C/g%3E%3Ccircle id='楕円形_6' data-name='楕円形 6' cx='10' cy='10' r='10' transform='translate(7 7)' fill='%23ACACAC'/%3E%3C/svg%3E%0A");
                    }
                  }
                }
              }
            }
          }

          label {
            font-size: 20px;
            font-weight: bold;
            position: relative;

            span {
              vertical-align: middle;
              line-height: 34px;
              margin-left: 10px;
            }

            &:before {
              content: "";
              vertical-align: middle;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'%3E%3Cg id='楕円形_7' data-name='楕円形 7' fill='%23fff' stroke='%23acacac' stroke-width='1'%3E%3Ccircle cx='17' cy='17' r='17' stroke='none'/%3E%3Ccircle cx='17' cy='17' r='16.5' fill='none'/%3E%3C/g%3E%3Cg id='楕円形_8' data-name='楕円形 8' transform='translate(7 7)' fill='none' stroke='%23acacac' stroke-width='1'%3E%3Ccircle cx='10' cy='10' r='10' stroke='none'/%3E%3Ccircle cx='10' cy='10' r='9.5' fill='none'/%3E%3C/g%3E%3C/svg%3E%0A");
              display: inline-block;
              width: 34px;
              height: 34px;
              opacity: 1;
              transition: opacity 0.2s linear;
            }

            &::after {
              content: "";
              vertical-align: middle;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              transition: opacity 0.2s linear;
              display: inline-block;
              width: 34px;
              height: 34px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .ContentModal {
    .panel {
      max-width: calc(100vw - 140px);
    }
  }
}
